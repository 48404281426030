.has-mega-menu .submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff; /* Adjust as needed */
  padding: 10px;
  border: 1px solid #ccc; /* Adjust as needed */
}

.has-mega-menu:hover .submenu {
  display: block;
}
.dzu-input {
  visibility: hidden;
}
.dz-message {
  overflow-y: scroll;
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  z-index: 9999;
}

.question-mark {
  cursor: pointer;
}

.question-mark:hover + .options {
  display: block;
}

.options {
  display: none;
}
/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip content */
.tooltip-content {
  visibility: hidden;
  width: 200px; /* Adjust width as needed */
  background-color: #f9f9f9;
  color: #000;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 100%; /* Position below the tooltip container */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add box shadow for better visibility */
}

/* Show tooltip content on hover */
.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

/* List styling */
.tooltip-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* List item styling */
.tooltip-content li {
  margin-bottom: 5px;
}

/* Style for the arrow */
.tooltip-content::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f9f9f9 transparent;
}
.gst{
  text-transform: uppercase;
}